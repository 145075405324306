import { useMutation } from 'react-query'
import {
	loadExportList,
	loadGetSavedExportFilter,
	loadSaveExportFilter,
	loadGetApplicationList,
	loadGetFilterList,
	loadGetFilterOptions,
	loadAddExportReport,
	loadRemoveExportReport,
	loadGetFormsList,
	loadGetFormFieldOptions,
} from '../services/export.service'
import { getLocalAuthDetails } from '../utils/common.util'

import { FILTER_TYPE_FETCH } from '../containers/export-panel/export.const'
export function useAddExportReport() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadAddExportReport(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export function useSaveExportFilter() {
	return useMutation(async (params = {}) => {
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: userData.asset_id,
			}
			let res = await loadSaveExportFilter(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export function useRemoveExportReport() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadRemoveExportReport(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export function useLoadExportList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await loadExportList(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export const useGetFilterList = requestData => {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadGetFilterList(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
export function useGetSavedReportFilter() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
				start_from: params.page_start,
				limit_value: params.page_limit,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await loadGetSavedExportFilter(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export const useGetApplicationList = payload => {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				asset_id: userData.asset_id,
				asset_token_auth: userData.authToken,
				account_id: userData.account_id,
				organization_id: userData.organization_id,
				workforce_id: userData.workforce_id,
			}
			let res = await loadGetApplicationList(payload)

			return handleGetApplicationResponse(res)
		} catch (error) {
			return error
		}
	})
}
const handleGetApplicationResponse = res => {
	const resp = res.data.response
	let applicationList = []
	if (resp.length) {
		applicationList = resp.map(app => {
			return {
				value: app.application_id,
				label: app.application_name,
			}
		})
	} else {
		applicationList = [
			{
				value: -1,
				label: 'NA',
			},
		]
	}
	res.data.response = applicationList
	return res
}

export const useGetProductCategoryList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}

export const getReportFilterValue = async (params = {}) => {
	const flag = params.access_level_id
	let userData = getLocalAuthDetails()
	try {
		let payload = {
			...userData,
			...params,
			target_asset_id: Number(userData.asset_id),
		}
		let res = await loadGetFilterOptions(payload)
		let resultValue = handleReportFilterResponse(res, flag)
		return resultValue
	} catch (error) {
		return error
	}
}

export const useGetReportFilterValue = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
/////////////////////subapplication List //////////////////
export const useSubApplicationList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
//////////////cluster list---------------------
export const useClusterList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
///-----------------segment List----------------
export const useSegmentList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Process List---------------------
export const useProcessList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag Type 1---------------------
export const useAssetTagType1 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag Type 2---------------------
export const useAssetTagType2 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag Type 3---------------------
export const useAssetTagType3 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag 1---------------------
export const useAssetTag1 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag 2---------------------
export const useAssetTag2 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
////--------Asset Tag 3---------------------
export const useAssetTag3 = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
///------------creater list---Resource list---------------------------
export const useCreaterList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}

////-------- Workforce List---------------------
export const useWorkforceList = () => {
	return useMutation(async (params = {}) => {
		const flag = params.access_level_id
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				target_asset_id: Number(userData.asset_id),
			}
			let res = await loadGetFilterOptions(payload)
			let resultValue = handleReportFilterResponse(res, flag)
			return resultValue
		} catch (error) {
			return error
		}
	})
}
///////////----------Process Forms List------------------------
export const useFetchFormsList = () => {
	return useMutation(async (params = {}) => {
		const flag = 30
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
				workforce_id: 0,
			}
			let res = await loadGetFormsList(payload)

			return handleReportFilterResponse(res, flag)
		} catch (error) {
			return error
		}
	})
}
//////////--- get formated value to return for filters option list
const handleReportFilterResponse = (res, flag) => {
	const resp = res.data.response
	let applicationList = []

	if ((resp.length && resp.length > 0) || (resp.data && resp.data.length)) {
		switch (flag) {
			case FILTER_TYPE_FETCH.BUILDING: ///building list
				applicationList = resp.map(building => {
					return {
						value: building.account_id,
						label: building.account_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.CREATER:
				// let creatorList = [];
				applicationList = res.data.response.map(creator => {
					return {
						value: creator.asset_id,
						label: creator.operating_asset_first_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.PROCESS:
				// let processList = [];
				applicationList = resp.map(process => {
					return {
						value: process.activity_type_id,
						label: process.activity_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.SUBAPPLICATION:
				// let subApplicationList = [];
				applicationList = resp.map(app => {
					return {
						value: app.tag_type_id,
						label: app.tag_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.APPLICATION:
				// let subApplicationList = [];
				applicationList = resp.map(app => {
					return {
						value: app.application_id,
						label: app.application_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.SEGMENT:
				// let segmentList = [];
				applicationList = resp.map(segment => {
					return {
						value: segment.segment_id,
						label: segment.segment_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.CATEGORY:
				// let categoryList = [];
				applicationList = resp.map(category => {
					return {
						value: category.product_tag_id,
						label: category.product_tag_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.PRODUCT_FAMILY:
				// let familyList = [];
				applicationList = resp.map(family => {
					return {
						value: family.activity_type_id,
						label: family.activity_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.PRODUCT:
				// let productList = [];
				applicationList = resp.map(product => {
					return {
						value: product.activity_id,
						label: product.activity_title,
					}
				})
				break
			case FILTER_TYPE_FETCH.CLUSTER:
				// let clusterList = [];
				applicationList = resp.map(cluster => {
					return {
						value: cluster.cluster_tag_id,
						label: cluster.cluster_tag_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.WORKFORCE_TAG:
				// let workforceTagList = [];
				applicationList = resp.map(workforce => {
					return {
						value: workforce.workforce_tag_id,
						label: workforce.workforce_tag_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.FORMS_LIST:
				///let formsList = [];
				applicationList = res.data.response.map(form => {
					return {
						value: form.form_id,
						label: form.form_name,
					}
				})
				applicationList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case FILTER_TYPE_FETCH.FIELDS_LIST:
				/// fieldList=[]

				const duplicateField = new Set()

				res.data.response.data.forEach(field => {
					if (!duplicateField.has(field.field_id)) {
						duplicateField.add(field.field_id)
						applicationList.push({
							value: field.field_id,
							label: field.field_name,
						})
					}
				})
				applicationList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG_TYPE1:
				applicationList = resp.map(field => {
					return {
						value: field.tag_type_id,
						label: field.tag_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG_TYPE2:
				applicationList = resp.map(field => {
					return {
						value: field.tag_type_id,
						label: field.tag_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG_TYPE3:
				applicationList = resp.map(field => {
					return {
						value: field.tag_type_id,
						label: field.tag_type_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG1:
				applicationList = resp.map(field => {
					return {
						value: field.tag_id,
						label: field.tag_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG2:
				applicationList = resp.map(field => {
					return {
						value: field.tag_id,
						label: field.tag_name,
					}
				})
				break
			case FILTER_TYPE_FETCH.ASSET_TAG3:
				applicationList = resp.map(field => {
					return {
						value: field.tag_id,
						label: field.tag_name,
					}
				})
				break

			default:
				applicationList = [
					{
						value: -1,
						label: 'NA',
					},
				]
		}
	} else {
		applicationList = [
			{
				value: -1,
				label: 'NA',
			},
		]
	}
	res.data.response = applicationList
	return res
}

///////-------------form field fetch------------
export const useFormFieldList = () => {
	return useMutation(async (params = {}) => {
		const flag = 29
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...params,
				account_id: Number(userData.account_id),
				asset_id: Number(userData.asset_id),
				asset_token_auth: userData.asset_token_auth,
				workforce_id: Number(userData.workforce_id),
				organization_id: Number(userData.organization_id),
				page_limit: 200,
				page_start: 0,
				device_os_id: 5,
			}
			let res = await loadGetFormFieldOptions(payload)
			return handleReportFilterResponse(res, flag)
		} catch (error) {
			return error
		}
	})
}
