//chat.hooks.js

import { useMutation } from 'react-query';
import {
  loadChatParticipants,
  loadUserChatParticipants,
  loadExistChatCheck,
} from '../services/chat.service';
import { getLocalAuthDetails } from '../utils/common.util';

export function useLoadChatParticipants() {
  return useMutation(async (params = {}) => {
    try {
      let payload = {
        ...getLocalAuthDetails(),
        ...params,
        start_from: params.page_start,
        limit_value: params.page_limit,
        service_version: 1.0,
        app_version: 1.0,
        device_os_id: 5,
      };
      // payload['target_asset_id'] = payload.asset_id;
      let res = await loadChatParticipants(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}

export const useLoadUserChatParticipantsList = () => {
  return useMutation(async (params = {}) => {
    try {
      let payload = {
        ...getLocalAuthDetails(),
        ...params,
        start_from: params.page_start,
        limit_value: params.page_limit,
        service_version: 1.0,
        app_version: 1.0,
        device_os_id: 5,
      };
      // payload['target_asset_id'] = payload.asset_id;
      let res = await loadUserChatParticipants(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
};
export const useLoadExistChatCheck = () => {
  return useMutation(async (params = {}) => {
    try {
      let payload = {
        ...getLocalAuthDetails(),
        ...params,
        start_from: params.page_start,
        limit_value: params.page_limit,
        service_version: 1.0,
        app_version: 1.0,
        device_os_id: 5,
      };
      // payload['target_asset_id'] = payload.asset_id;
      let res = await loadExistChatCheck(payload);
      // TODO -- Add code if Not Exist Create New Chart
      // // TODO -- Add code if Exist Fetch Chat Time Line Data
      return res;
    } catch (error) {
      return error;
    }
  });
};
