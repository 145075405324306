import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import {
	loadRepositoryList,
	loadFolderContent,
	removeFolderContent,
	createNewFolder,
	renameFolder,
	loadSharedFolderUsers,
	searchRoleContacts,
	searchOrgContacts,
	searchWorkforceContacts,
	setWorkforceRoleAccess,
	shareRepoUser,
	loadSearchedDocs,
	removeUserShareDoc,
	docRepoPreviewInsert,
	docRepoPreviewList,
} from '../services/documents.service'
import {
	reorganizeDocs,
	reOrganizeSharedUsers,
} from '../containers/repository/repo.utils'
export function useGetRepositoryList() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await loadRepositoryList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useDocTypeContentList() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await loadRepositoryList(payload)
			if (!!res.data.response) {
				let content = await reorganizeDocs(
					res.data.response,
					Number(userData.asset_id)
				)
				return content
			} else {
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}
export function useFolderContentList() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await loadFolderContent(payload)
			if (!!res.data.response) {
				let content = await reorganizeDocs(
					res.data.response,
					Number(userData.asset_id)
				)
				res.data.response = content
				return res
			} else {
				return res
			}
		} catch (error) {
			return error
		}
	})
}
/////-remove docs content--------------
export function useRemoveDocument() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await removeFolderContent(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

/////-- Create Folder-------------------
export function useCreateFolder() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await createNewFolder(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
/////----- Rename Folder----------------
export function useRenameFolder() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await renameFolder(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

//////--------shared users list-----------
export function useGetSharedUsreList() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
			}
			let res = await loadSharedFolderUsers(payload)
			if (!!res.data.response) {
				let content = await reOrganizeSharedUsers(res.data.response)
				res.data.response = content
				return res
			} else {
				return res
			}
		} catch (error) {
			return error
		}
	})
}
//////-------------search user org list-----------------
export function useSearchOrgContacts() {
	return useMutation(async ({ search_string, start_from }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				search_string,
				is_search: 1,
				is_sort: 0,
				is_status: 0,
				limit_value: 10,
				start_from: start_from ? start_from : 0,
				activity_type_category_id: 5,
			}
			let res = await searchOrgContacts(payload)
			return res.data.response.map(user => {
				const { activity_inline_data } = user
				const data = JSON.parse(activity_inline_data)
				return {
					asset_id: user.asset_id,
					asset_name: user.operating_asset_first_name || '',
					asset_country_code: data.contact_phone_country_code,
					asset_phone: data.contact_phone_number,
					asset_type_id: user.asset_type_id,
					asset_type_name: user.asset_type_name,
					document_access_level_id: 6,
				}
			})
		} catch (error) {
			return error
		}
	})
}
////---------------search user Role list-----------
export function useSearchRoleContacts() {
	return useMutation(async ({ search_string }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				search_string,
				asset_type_category_id: 3,
				asset_type_id: 0,
				start_from: 0,
				flag: 0,
				level_id: 0,
				page_start: 0,
				page_limit: 500, /// TODo ---- recurcive call
			}
			let res = await searchRoleContacts(payload)
			return res.data.response.map(role => {
				return {
					asset_id: role.asset_type_id,
					asset_name: role.asset_type_name || '',
					asset_country_code: '',
					asset_phone: '',
					asset_type_id: '',
					asset_type_name: '',
					document_access_level_id: 5,
				}
			})
		} catch (error) {
			return error
		}
	})
}

//////    ----search user workforce list----------
export function useSearchWorkforceContacts() {
	return useMutation(async ({ search_string }) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				asset_type_id: 0,
				search_string,
				account_id: 0,
				start_from: 0,
				limit_value: 500, /// TODo ---- recurcive call
			}
			let res = await searchWorkforceContacts(payload)
			return res.data.response.map(workforce => {
				return {
					asset_id: workforce.workforce_id,
					asset_name: workforce.workforce_name || '',
					sub_title: workforce.account_name || '',
					asset_country_code: '',
					asset_phone: '',
					asset_type_id: '',
					asset_type_name: '',
					document_access_level_id: 3,
				}
			})
		} catch (error) {
			return error
		}
	})
}
/////------------setWorkforce access role for docs--------------

export function useSetWorkforceRoleAccess() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			return await setWorkforceRoleAccess(payload)
		} catch (error) {
			return error
		}
	})
}

///////////-------sharing docs----------------
export function useShareRepository() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			return await shareRepoUser(payload)
		} catch (error) {
			return error
		}
	})
}

export function useSearedDocs() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			let res = await loadSearchedDocs(payload)
			if (!!res.data.response) {
				let content = await reorganizeDocs(
					res.data.response,
					Number(userData.asset_id)
				)
				res.data.response = content
				return res
			} else {
				return res
			}
		} catch (error) {
			return error
		}
	})
}

export function useRevokeShare() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}

			let res = await removeUserShareDoc(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useDocRepoPreviewInsert() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}

			let res = await docRepoPreviewInsert(payload)
			return res
		} catch (error) {
			return error
		}
	})
}

export function useDocRepoPreviewList() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}

			let res = await docRepoPreviewList(payload)
			return res
		} catch (error) {
			return error
		}
	})
}